@import 'tailwindcss/base';

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #eaf0fe transparent;
}

.dark-scrollbar {
  scrollbar-color: #2d374861 transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

#root,
.MuiModal-root:not(.MuiMenu-root),
.MuiMenuItem-root,
.MuiAutocomplete-listbox,
div[role='tooltip'].MuiTooltip-popper > .MuiTooltip-tooltip,
.zoom-adjusted-container {
  zoom: 0.8;
}

.MuiTabs-indicator,
.zoom-countered-element {
  zoom: 1.25;
}

.no-zoom {
  zoom: 1.25 !important;
}

.custom-select.is-active > fieldset {
  border-color: rgba(49, 116, 250, 0.6) !important;
  background-color: #f7f9ff !important;
  z-index: -1;
}

.base-Popper-root.MuiAutocomplete-popper {
  @apply !px-10;
}

.px-sm.base-Popper-root.MuiAutocomplete-popper {
  @apply !px-4;
}

.react-flow__controls-button {
  @apply !border-b-0 flex-1 !w-8 !h-6 !bg-white;
  fill: #000;
}

.react-flow__controls-button:hover {
  @apply !bg-white;
  fill: #3174fa !important;
}

.react-flow__controls-button.react-flow__controls-zoomin {
  @apply !order-2 rounded-l-lg;
}

.react-flow__controls-button.react-flow__controls-zoomout {
  @apply !order-4 rounded-r-lg;
}

.dotted-bg {
  @apply bg-flow-view;
  background-image: radial-gradient(#c4c4d0 0.7px, transparent 0);
  background-size: 16px 16px;
}

.dotted-bg-dark {
  @apply bg-flow-view;
  background-color: rgb(172 177 181) !important;
  background-image: radial-gradient(#6e6e6e 0.7px, transparent 0);
  background-size: 16px 16px;
}

.readonly.preview-mode .node-check,
.readonly.preview-mode .node-type-list,
.readonly.preview-mode .actions,
.readonly.preview-mode div[class*='-block'] .node-check,
.readonly.preview-mode .freeform-block,
.readonly.preview-mode .conditional-block,
.readonly.preview-mode .retry-block,
.readonly.preview-mode .stop-block,
.readonly.preview-mode .request-block {
  @apply !pointer-events-none opacity-70;
}

.readonly.preview-mode .add-node-btn,
.readonly.preview-mode .delete-node-btn,
.readonly.preview-mode .add-btn {
  @apply !hidden;
}

.react-flow.readonly.error-push
  .react-flow__edgelabel-renderer
  .nodrag
  .bg-white {
  opacity: 0.8;
}

.react-flow.readonly.error-push .react-flow__background {
  background-color: rgba(0, 0, 0, 0.2);
}

.react-flow.readonly.error-push .react-flow__node .ring-transparent {
  @apply !relative before:absolute before:z-[99] before:content-[''] before:left-0 before:top-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.3)];
}

#sentry-feedback {
  --inset: auto auto 0 0;
  --left: calc(100% - 164px);
}

.react-flow.readonly .add-btn {
  @apply !pointer-events-none !hidden;
}

.react-flow.readonly .edge-label {
  @apply !bg-gray-300;
}

.retry-node-contrast circle {
  fill: #103d61;
  stroke: white;
}

.retry-node-contrast path[stroke='#103D61'] {
  stroke: white;
}

.retry-node-contrast path[stroke='white'] {
  stroke: #103d61;
}

.MuiSelect-multiple.MuiInputBase-input.MuiOutlinedInput-input {
  padding-top: 1.25rem;
  width: 9rem;
}

.ql-editor,
.ql-container {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
}
.no-multiline .ql-editor {
  white-space: nowrap;
  overflow: auto hidden;
  word-break: normal;
  overflow-wrap: normal;
}

.custom-tooltip {
  position: relative;
}
.tooltiptext {
  background-color: #000000;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  position: absolute;
  white-space: normal;
  word-wrap: break-word;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tooltiptext::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent #000000 transparent transparent;
}

.break-word-span {
  word-break: break-word;
  overflow-wrap: break-word;
}

.no-backdrop {
  opacity: 0 !important;
}

#sentry-feedback-btn {
  display: none;
  position: fixed;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 50rem;
  padding: 0.5rem 0.75rem;
  background-color: #29232f;
}
